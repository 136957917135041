import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Layout from '../../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography variant='body1' gutterBottom align='center'>
            Thank you for registering to attend the Expert Group Meeting (EGM) for the UNIDO International Centre for
            Health Industry Readiness. This important event will take place on 4-5 November 2024 at the Abu Dhabi
            Department of Health Headquarters. We look forward to your valuable contributions as we explore the Centre’s
            operating model and long-term strategy in alignment with the Abu Dhabi Healthcare Life Science Strategy
            2040.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
